module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"],"mergeSecurityHeaders":false,"headers":{"/*":["Content-Security-Policy: frame-ancestors https://app.zoominfo.com","Access-Control-Allow-Origin: https://app.zoominfo.com","X-Content-Type-Options: nosniff","X-Frame-Options: ALLOW-FROM https://app.zoominfo.com","X-XSS-Protection: 1; mode=block"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4c0ddfc8da38bb559f95b94e6f893e2e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://trustgatsby.planful.com/graphql","schema":{"perPage":20,"requestConcurrency":5,"typePrefix":"Wordpress","previewRequestConcurrency":2,"timeout":6000000,"queryDepth":15,"circularQueryLimit":5},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
